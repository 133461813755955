:root {
  --background: #282a36;
  --foreground: #f8f8f2;
  --gray: #44475a;
  --blue-gray: #6272a4;
  --green: #50fa7b;
  --red: #ff5555;
}

body {
  background-color: var(--background);
  color: var(--foreground);
}

.App {
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
}

.drop-zone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: var(--blue-gray);
  border-style: dashed;
  background-color: var(--gray);
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

img {
  margin: 20px;
  border-radius: 5px;
  max-width: 700px;
  max-height: 700px;
}
